<template>
  <div class="container">
    <MyBackTop :bottom="200"></MyBackTop>

    <div style="background-color: #ffffff;border-bottom: solid 1px #e6e6e6;">
      <div class="padding-lr" style="z-index: 10">
        <div class="menuItemX" index="1" style="padding-left: 0;border: 0;display:flex;align-items:center;justify-content:space-between">
          <img src="../assets/img/header/unicorn.png" alt="中科心客——创投基金—预见独角兽计划—科技成果转移转化" style="width: 9.375rem;height: 3rem;" />
          <i class="el-icon-s-fold" :style="{'display':showNavButton?'':'none','font-size':'2rem','float':'right'}" @click.stop="handleNavMenuBtn"></i>
        </div>
        <el-menu
          :mode="navType"
          text-color="#333"
          active-text-color="#FF870E"
          :default-active="activeIndex"
          class="head-el-menu"
          @select="handleSelect"
        >
          <!-- <el-menu-item class="menuItemX" index="1" style="padding-left: 0;border: 0;display:flex;align-items:center;justify-content:space-between">
            <img src="../assets/img/header/unicorn.png" alt="中科心客——创投基金—预见独角兽计划—科技成果转移转化" style="width: 9.375rem;height: 3rem;" />
            <i class="el-icon-s-fold" :style="{'display':showNavButton?'':'none','font-size':'2rem','float':'right'}" @click.stop="handleNavMenuBtn"></i>
          </el-menu-item> -->
          <!-- 用一个 div 包裹手机端 nav 选项 -->
          <template>
            <el-menu-item :class="[hideNavMenu?'hidenone':'']" index="1"><router-link :to="{ name: 'Home', query: {} }">首页</router-link></el-menu-item>

            <el-menu-item :class="[hideNavMenu?'hidenone':'']" index="2"><router-link :to="{ name: 'MyTV', query: {} }">卫视栏目</router-link></el-menu-item>
            <el-menu-item :class="[hideNavMenu?'hidenone':'']" index="10"><router-link :to="{ name: 'Program', query: {} }">往期节目</router-link></el-menu-item>
            <el-menu-item :class="[hideNavMenu?'hidenone':'']" index="11"><router-link :to="{ name: 'Handpick', query: {} }">精选内容</router-link></el-menu-item>

            <el-menu-item :class="[hideNavMenu?'hidenone':'']" index="3"><router-link :to="{ name: 'Fund', query: {} }">创投基金</router-link></el-menu-item>
            <el-menu-item :class="[hideNavMenu?'hidenone':'']" index="4"><router-link :to="{ name: 'UnicornAcademy', query: {} }">独角兽训练营</router-link></el-menu-item>
            <el-menu-item :class="[hideNavMenu?'hidenone':'']" index="5"><router-link :to="{ name: 'Summit', query: {} }">预见独角兽峰会</router-link></el-menu-item>
            <el-menu-item style="padding-right: 0;" :class="[hideNavMenu?'hidenone':'']" index="6"><router-link :to="{ name: 'IncubationService', query: {} }">独角兽培育服务</router-link></el-menu-item>
            <!-- <el-menu-item style="padding-right: 0;" :class="[hideNavMenu?'hidenone':'']" index="7"><router-link :to="{ name: 'CityAudition', query: {} }">城市海选站</router-link></el-menu-item> -->
            <!-- <el-submenu :class="[hideNavMenu?'hidenone':'']" index="8">
              <template slot="title">
                关于预见独角兽
              </template>
              <el-menu-item index="8-1"><router-link :to="{ name: 'ListSelection', query: {} }">关于我们</router-link></el-menu-item>
              <el-menu-item index="8-2"><router-link :to="{ name: 'TeamIntroduction', query: {} }">团队介绍</router-link></el-menu-item>
              <el-menu-item index="8-3"><router-link :to="{ name: 'ActivityMatch', query: {} }">团队查询</router-link></el-menu-item>
            </el-submenu> -->
            <!-- <el-menu-item :class="[hideNavMenu?'hidenone':'']" index="9"><router-link :to="{ name: 'TeamIntroduction', query: {} }">团队介绍</router-link></el-menu-item> -->
            <label class="loginText" v-if="!tokenVal">
            	<span @click="loginFun()">账号登录 </span>
            </label>
            <label class="loginText" v-else style="display: flex;">
              <el-menu :default-active="actIndex" class="el-menu-demo" mode="horizontal">
                <el-submenu index="1" >
                  <template slot="title">{{phone}}</template>
                  <el-menu-item @click="loginOut()" index="1-1">退出登录</el-menu-item>
                </el-submenu>
              </el-menu>
            </label>

          </template>
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
import MyBackTop from '@/components/SideBar.vue';
import { mapState } from 'vuex'
import { getToken,getPhone } from '@/utils/auth'

export default {
  name: '',
  components: {
    MyBackTop
  },
  data () {
    return {
      activeIndex: '1',
      actIndex: '1',
      keyword: '',
      // showNavButton: false,
      clientWidth: 1200,
      hideNavMenu: false,
      showNavButton: false,
      tokenVal:getToken(),
      phone:getPhone()
    };
  },
  computed: {
    ...mapState({
      isMobile: state => state.isMobile
    }),
    // 头部导航栏显示的方式
    navType () {
      console.log('this.isMobile---head')
      console.log(this.isMobile);
      return this.isMobile ? 'vertical' : 'horizontal'
    }
  },
  created () {
    
  },
  mounted () {
    let selectIndex = sessionStorage.getItem('activeIndex');
    this.activeIndex = selectIndex || '1';
    this.$router.beforeResolve((to, from, next) => {
      this.tokenVal = getToken();
      let selectIndex = sessionStorage.getItem('activeIndex');
      this.activeIndex = selectIndex || '1';
      next();
    });
    this.$router.beforeEach((to, from, next) => {
    	this.tokenVal = getToken();
      let selectIndex = sessionStorage.getItem('activeIndex');
      this.activeIndex = selectIndex || '1';
    	next();
    });
  },
  methods: {
    /** * navItemClick
    * @description 当在移动端浏览网站时，对于 nav-link 进行监听，实现导航栏展开 与 伸缩
    */
    navItemClick () {
      console.log('navClick')
    },
    handleSelect (key, keyPath) {
      switch (key) {
        case "1":
          this.$router.push({ name: "Home", query: {} });
          break;
        case "8-1":
          this.$router.push({ name: "AboutUs", query: {} });
          break;
        case "8-2":
          this.$router.push({ name: "TeamIntroduction", query: {} });
          break;
        case "8-3":
          this.$router.push({ name: "TeamQuery", query: {} });
          break;
        case "2":
          this.$router.push({ name: "MyTV", query: {} });
          break;
        case "3":
          this.$router.push({ name: "Fund", query: {} });
          break;
        case "4":
          this.$router.push({ name: "UnicornAcademy", query: {} });
          break;
        case "5":
          this.$router.push({ name: "Summit", query: {} });
          break;
        case "6":
          this.$router.push({ name: "IncubationService", query: {} });
          break;
        case "7":
          this.$router.push({ name: "CityAudition", query: {} });
          break;
        case "8":
          this.$router.push({ name: "UnicornAcademy", query: {} });
          break;
        case "9":
          this.$router.push({ name: "TeamIntroduction", query: {} });
          break;
        case "10":
          this.$router.push({ name: "Program", query: {} });
          break;
        case "11":
          this.$router.push({ name: "Handpick", query: {} });
          break;
        default:
          break;
      }
      if (key) {
        sessionStorage.setItem('activeIndex', key);
      }
    },
    search (e) {
      this.hideNavMenu = true
      sessionStorage.setItem('keyword', this.keyword);
      this.$router.push({ name: 'Search', query: { timestamp: new Date().getTime() } });
    },
    /* 登录账号 */
    loginFun(){
    	this.$router.push({ name: 'Login', query: {} });
    	this.activeIndex = "1";
    	sessionStorage.setItem('activeIndex', "1")
    },
    /* 退出登录 */
    loginOut(){
    	this.$store.dispatch('LogOut').then((res) => {
    	  if (res) {
          this.activeIndex = "1";
    	    this.$router.push({ name: 'Login', query: {} });
    	  } else {
    	    this.$message({ type: 'error', message: '用户退出出错', })
    	  }
    	})
    },
    /*
    * @method initClientWidth (获取视窗宽度，并监听视窗宽度 赋值给 this.clientWidth)
    * @param  null
    * @return null
    */
    // initClientWidth() {
    //   this.clientWidth = document.documentElement.clientWidth
    //   console.log('initWidth');
    //   console.log(this.clientWidth);
    //   this.$nextTick(() => {
    //     window.addEventListener('resize', () => {
    //       this.clientWidth = document.documentElement.clientWidth
    //     });
    //   })
    //   console.log(this.clientWidth)
    // },
    /*
    * @method handleNavMenuBtn ( 导航栏菜单按钮 )
    * @param  null
    * @return null
    */
    handleNavMenuBtn () {
      if (this.isMobile) {
        this.hideNavMenu = !this.hideNavMenu
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .menuItemX{
    margin-right: 0px !important;
    margin-left: -40px;
  }
/* 去除默认样式 */
a {
  text-decoration: none;
  color: #333;
}
a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:hover {
  color: #f08519;
  text-decoration: none;
}
a:active {
  text-decoration: none;
}

.padding-lr {
  // padding: 0 10vw;
  width: 1200px !important;
  margin: 0 auto !important;
  padding: 0 !important;
  display: flex;
  justify-content: space-between;
}

.head-el-menu{
  position: relative;
  // margin-left: 0.5rem;
  // width: 100%;
  z-index:10;
  border-bottom: 0 !important;
  .hidenone{
    display: none;
  }
  .el-menu-item{
    padding: 0 30px;
    border: 0 !important;
  }
}

.search {
  position: absolute;
  right: -3%;
  width: 16.625rem;

  .search-input {
    display: inline-block;

    input {
      margin-left: -1rem;
      padding: 0 2.375rem 0 0.625rem;
      width: 16.25rem;
      height: 1.875rem;
      background-color: #f8f8f8;
      border: 0;
    }

    img {
      position: absolute;
      top: 50%;
      right: 0.625rem;
      transform: translateY(-45%);
      width: 1.375rem;
      height: 1.375rem;
    }
  }
}
  .loginText{
		height: 60px;
		line-height: 60px;
		font-size: 14px;
		cursor: pointer;
		color: #0093ff;
		margin-left: 20px;
    .el-submenu{
      height: 60px;
    }

	}
  ::v-deep .el-menu--horizontal .el-menu .el-submenu__title{
    height: 60px;
    line-height: 60px;
    padding-left: 30px;
    color: #FF870E;
  }
  ::v-deep .el-menu--horizontal .el-menu .el-submenu__title:hover{
    color: #FF870E;
  }
// hideNone
// display: none

@media screen and (min-width: 1918px) {
  .search {
    right: 1.5%;
  }
}

// @media screen and (min-width: 1024px) {
//   .container{
//     width:100%!important;
//   }
// }

@media screen and (max-width: 1601px) {
  .padding-lr {
    padding: 0 ;
  }

  .search {
    right: 1.5%;
  }
}

@media (max-width: 1024px) {
  .menuItemX{
    margin-right: 0px !important;
    padding-right: 20px !important;
  }
  .self-wrap-class{
    .container{
      width: 100%!important;
      padding: 0!important;
    }
    .head-el-menu{
      margin-left: 0;
    }
    .padding-lr {
      padding: 0 ;
    }
    .el-submenu .el-submenu__title{
      padding: 0 .2rem!important;
    }
    .search {
      position: static;
      width: 10rem;
      transform: translateY(0);
      right: 0;

      background-color: #ffffff;
      .search-input{
        height: 1.5rem;
        line-height: 1.5rem;
        input{
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .self-wrap-class{
    .el-menu-item,.el-submenu {
      width: 100%;
    }
  }
    // .search{
    //   position: static;
    // }
}
</style>
