<template>
  <div class="containers" @mousewheel.prevent @DOMMouseScroll.prevent>
    <!-- 轮播图 -->
    <el-carousel ref="carouselRef" class="carouselContainers" height="100%" indicator-position="none" direction="vertical" :autoplay="false" :loop="false">
    <el-carousel-item style="height: 100%;" class="carouselBox centerAlign">
      <!-- <el-carousel height="100%" style="position: relative; background-color: #efefef; height: 100%; overflow: hidden;" :interval="4000">
        <el-carousel-item style="height: 100%;" v-for="(res) in bannerList" :key="res.id">
          <el-image :src="res.picture" fit="fill" style="width: 100%;height: 100%;cursor: pointer;"></el-image>
        </el-carousel-item>
      </el-carousel> -->
      <div class="newsInfoBox">
        <div class="newsInfoLeft">
          <div class="newsInfoImg">
            <!-- <el-image :src="mobileCarouselImg" fit="fill" style="width: 100%;height: 100%;cursor: pointer;"></el-image> -->
            <el-carousel height="100%" style="position: relative; background-color: #efefef; height: 100%; overflow: hidden;"
            :interval="4000" arrow="never" @change="carouselHand">
              <el-carousel-item style="height: 100%;" v-for="(res,index) in journalismList" :key="index" v-if="index < 4">
                <el-image @click="newsInfo('2',journalismList[initialIndex].id)" :src="res.coverImg" fit="fill" style="width: 100%;height: 100%;cursor: pointer;"></el-image>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="newsInfoTitle line1" @click="newsInfo('2',journalismList[initialIndex].id)">{{journalismList[initialIndex].title}}</div>
          <div class="newsInfoText line15" v-html="journalismList[initialIndex].content"></div>
        </div>
        <div class="newsInfoRight">
          <div class="newsInfoTopic flex_between">
            <div class="topicName">新闻资讯</div>
            <div class="examineAllBut" @click="newsInfo('1','')">查看更多<i class="el-icon-caret-right"></i></div>
          </div>
          <div class="newsLi" v-for="(res,index) in journalismList" :key="index" @click="newsInfo('2',res.id)">
              <div class="newsTag"></div>
              <div class="newsTitle line1">{{res.title}}</div>
              <div class="newsTime">{{formatDate(res.releaseTime)}}</div>
          </div>
        </div>
      </div>
    </el-carousel-item>
    <!-- 预见独角兽卫视栏目 -->
    <el-carousel-item class="moduleBox centerAlign" style="position: relative;">
      <div class="columnBack"></div>
      <div class="tvBox">
        <div class="tvTopInfo">
          <div class="tvTopLeft">
            <div class="tvTopTitleBox" @click="handleUrl('1')">
              <div class="tvTopTitle">预见独角兽卫视栏目</div>
              <el-image class="columnImg" :src="require('@/assets/img/home/homeImg_1.png')" fit="cover"></el-image>
              <!-- <el-image class="columnImg" :src="require('@/assets/img/home/homeImg_2.png')" fit="cover"></el-image> -->
            </div>
            <div class="tvIntroduce">
              <label>知名主持人</label>
              <label>创投大咖</label>
              <label>头部流量达人</label>
              <label>加盟渠道共同助力</label>
            </div>
          </div>
          <div class="tvTopRight">
            <div class="tvTopText" style="padding-top: 1.2rem;">
              独角兽项目发展困难重重，如何解决项目融资难、市场壁垒高、招商加盟难、品牌影响力弱等问题迫在眉睫。
              为此，预见独角兽联合东方卫视、梅花创投、红杉资本、金沙江创投等超百家国内一线资机构、
              网络达人进行深度合作，打造创投类大型真人秀《预见独角兽·青创英雄汇》栏目。树立行业标杆，
              帮助越来越多优秀的创业者成为伟大的企业家。
            </div>
          </div>
        </div>
        <div class="moreBox" @click="handleUrl('1')">
          <el-image class="moreImg" :src="require('@/assets/img/home/homeImg_9.png')" fit="cover"></el-image>
          <div class="moreBut">查看更多</div>
        </div>
        <el-row class="columnDataBox">
          <el-col :span="4" style="height: 100%;">
            <div class="columnDataLi borBom1">
              <el-image class="tvProgramaImg" :src="require('@/assets/img/home/homeImg_3.png')" fit="cover"></el-image>
              <div class="tvProgramaNum"><span>{{tvInfo.dataNum0}}</span><span class="font_3" style="margin: 0;">月</span></div>
              <div class="tvProgramaText">已经播出</div>
            </div>
          </el-col>
          <el-col :span="4" style="height: 100%;">
            <div class="columnDataLi2 borBom2 borRig">
              <el-image class="tvProgramaImg" :src="require('@/assets/img/home/homeImg_4.png')" fit="cover"></el-image>
              <div class="tvProgramaNum">
                <countTo class="font_1" separator="" :startVal='0' :endVal='tvInfo.dataNum1' :duration='2000'></countTo>
                <span class="font_1" style="margin: 0 10px 0 -15px;">万</span>
                <span class="font_3">+</span>
                <!-- <span>10000</span><span class="font_3">+</span> -->
              </div>
              <div class="tvProgramaText">企业报名参加</div>
            </div>
          </el-col>
          <el-col :span="4" style="height: 100%;">
            <div class="columnDataLi2 borBom1 borRig">
              <el-image class="tvProgramaImg" :src="require('@/assets/img/home/homeImg_5.png')" fit="cover"></el-image>
              <div class="tvProgramaNum">
                <countTo class="font_1" separator="" :startVal='0' :endVal='tvInfo.dataNum2' :duration='2000'></countTo>
                <span class="font_3">+</span>
                <!-- <span>1000</span><span class="font_3">+</span> -->
              </div>
              <div class="tvProgramaText">投资机构参与</div>
            </div>
          </el-col>
          <el-col :span="4" style="height: 100%;">
            <div class="columnDataLi2 borBom2 borRig">
              <el-image class="tvProgramaImg" :src="require('@/assets/img/home/homeImg_6.png')" fit="cover"></el-image>
              <div class="tvProgramaNum">
                <countTo class="font_1" separator="" :startVal='0' :endVal='tvInfo.dataNum3' :duration='2000'></countTo>
                <span class="font_3">余座</span>
                <!-- <span>40</span><span class="font_3">余座</span> -->
              </div>
              <div class="tvProgramaText">覆盖城市</div>
            </div>
          </el-col>
          <el-col :span="4" style="height: 100%;">
            <div class="columnDataLi2 borBom1 borRig">
              <el-image class="tvProgramaImg" :src="require('@/assets/img/home/homeImg_7.png')" fit="cover"></el-image>
              <div class="tvProgramaNum">
                <countTo class="font_1" separator="" :startVal='0' :endVal='tvInfo.dataNum4' :duration='2000'></countTo>
                <span class="font_3">+</span>
                <!-- <span>30</span><span class="font_3">+</span> -->
              </div>
              <div class="tvProgramaText">聚焦产业</div>
            </div>
          </el-col>
          <el-col :span="4" style="height: 100%;">
            <div class="columnDataLi2 borBom2">
              <el-image class="tvProgramaImg" :src="require('@/assets/img/home/homeImg_8.png')" fit="cover"></el-image>
              <div class="tvProgramaNum">
                <span class="font_3" style="margin: 0 -12px 0 0;">超</span>
                <countTo class="font_1" separator="" :startVal='0' :endVal='tvInfo.dataNum5' :duration='2000'></countTo>
                <!-- <span>60</span> -->
                <span class="font_3">亿元</span>
              </div>
              <div class="tvProgramaText">累积融资</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-carousel-item>
    <!-- 预见规则 -->
    <el-carousel-item class="ruleBox centerAlign">
      <div class="rule1200">
        <div class="ruleTopBox">
          <div class="ruleTopLeft">
            <div class="ruleTitle">预见·规则</div>
            <div class="subheadSubhead">共同冲击创投新传奇</div>
          </div>
          <div class="ruleTopRight">
            节目每期固定1位主持人，并搭配4位业内知名投资人组成青创筑梦团、数位行业大咖组成大咖筑力团。
            节目将以青创筑梦官和大咖助力团的专业视角给予青春创客答疑解惑，完成创投，共同冲击创投新传奇。
          </div>
        </div>
        <el-row :gutter="0" style="width: 100%;">
          <el-col :span="8">
            <div class="ruleLi">
              <el-image :src="ruleImg1" fit="fill" style="width: 100%;height: 100%;cursor: pointer;"></el-image>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="ruleLi" style="padding: 0 24px;">
              <div class="ruleName">商业对话·梦想更近一步</div>
              <div class="ruleText">
                <div class="ruleBz"></div>
                创客与青春筑梦官和大咖筑力团进行对话，创业的经历，奋斗的过程，未来的规划，在问答中逐一展现。
              </div>
              <div class="ruleText">
                <div class="ruleBz"></div>
                对话过程中，青春筑梦团和大咖筑力团随时选择亮灯表示是否心动，任意一灯点亮即可入群，进入独角兽候选池，进行创投孵化。
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="ruleLi">
              <el-image :src="ruleImg2" fit="fill" style="width: 100%;height: 100%;cursor: pointer;"></el-image>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="ruleLi" style="padding: 0 24px;">
              <div class="ruleName">现场展示·项目自由阐述</div>
              <div class="ruleText">
                <div class="ruleBz"></div>
                创客出场，自我介绍，由创客完成自己的创业项目阐述。
              </div>
              <div class="ruleText">
                <div class="ruleBz"></div>
                演讲、脱口秀、VCR、互动游戏、多种创意的表现方式完成阐述。
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="ruleLi">
              <el-image :src="ruleImg3" fit="fill" style="width: 100%;height: 100%;cursor: pointer;"></el-image>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="ruleLi" style="padding: 0 24px;">
              <div class="ruleName">终极测试·创业梦想抉择瞬间</div>
              <div class="ruleText">
                <div class="ruleBz"></div>
                创客面对终极压力测试，青春筑梦官、大咖筑力官与创客进行品牌价值、投资思路的交锋，创客决定是否进行估值调价。
              </div>
              <div class="ruleText">
                <div class="ruleBz"></div>
                青春筑梦团和大咖筑力团做出最终抉择，如果任意阵营点灯全亮，则创投筑梦成功，创客与阵营代表进行荣耀签约。如两方阵营点灯全亮，创客进入反选环节，由创客选择与一方阵营荣耀签约。
              </div>
            </div>
          </el-col>
        </el-row>


      </div>
    </el-carousel-item>

    <!-- 中科心客基金 -->
    <el-carousel-item class="moduleBox centerAlign">
      <div class="xinkeBox">
        <div class="xinkeFundTitle" @click="handleUrl('2')">中科心客基金</div>
        <div class="xinkeFundIntroduce">
          <label>基金直投</label>
          <label>多维赋能</label>
        </div>
        <div class="moreBox" style="margin-top: 0;" @click="handleUrl('2')">
          <el-image class="moreImg" :src="require('@/assets/img/home/homeImg_9.png')" fit="cover"></el-image>
          <div class="moreBut">查看更多</div>
        </div>
        <div class="xinkeFundContainer">
          <div style="overflow: hidden;">
            <div @click="inx = res.id" :class="inx == res.id?'enterpriseBox xzActive':'enterpriseBox'" v-for="(res) in enterpriseList" :key="res.id">
              <div class="enterpriseImgBox transition">
                <el-image class="enterpriseImg" :src="res.img" ></el-image>
              </div>
            </div>
          </div>
          <div style="overflow: hidden;">
            <div class="enterpriseName">{{TextList[inx-1].name}}</div>
            <div class="enterpriseText line4">{{TextList[inx-1].text}}</div>
          </div>
        </div>
        <div style="margin-top: 20px;"></div>
      </div>
    </el-carousel-item>
    <!-- 独角兽训练营 -->
    <el-carousel-item class="moduleBox centerAlign" style="background-color: #FCFAF8; height: calc(100vh - 60px);margin-top: 60px;">
      <div class="xinkeBox">
        <div class="xinkeFundTitle" @click="handleUrl('3')">独角兽训练营</div>
        <div class="xinkeFundIntroduce">
          <label>名师带徒</label>
          <label>名企游学</label>
        </div>
        <div class="trainingCampBox flex_between">
          <div class="trainingCampLeft">
            <el-image class="trainingCampImg" :src="trainingImg" ></el-image>
            <div class="trainingCampText">
              “独角兽训练营”以“名师招徒”的模式，让创业者与导师形成紧密的“师徒”关 系，
              促使“师傅”利用自身认知和广泛的资源帮助“徒弟”提升创业成功概率，通过导师引路，解
              决创业难题；让同量级的创业者与创业者之间形成高质量的“同窗”关系，不断扩充人脉，
              形成相互之间正能量推动的圈层社交，从提升创业者的投融资能力、市场生存能力、开拓
              创新能力、经营管理能力出发，帮助更多的创业者实现创业梦想。
            </div>
            <div style="text-align: right;">
              <el-image class="trainingCampImg" :src="trainingImg2" ></el-image>
            </div>
          </div>
          <div class="trainingCampRight">
            <el-image class="exhibitionImg transition" :src="require('@/assets/img/home/unicornImg_1.png')" fit="cover"></el-image>
            <div class="exhibitionBox flex_between">
              <el-image class="exhibitionImg2 transition" :src="require('@/assets/img/home/unicornImg_2.png')" fit="cover"></el-image>
              <div class="readMoreBox verticals" @click="handleUrl('3')">
                <div style="display: flex;">
                  <div class="readMoreBut">查看更多</div>
                  <el-image class="readMoreImg" :src="require('@/assets/img/home/homeImg_22.png')" fit="cover"></el-image>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mentorBox">
          <el-carousel height="100%" style="position: relative; height: 100%; overflow: hidden;" :autoplay="false" arrow="always">
            <el-carousel-item style="height: 100%;" v-for="(res) in mentorList" :key="res.id">
              <div class="mentorLi">
                <div class="mentorInfo transition" v-for="(item,index) in res.list" :key="item.id">
                  <el-image class="mentorImg" :src="require('@/assets/img/home/unicornImg_' + (index + 3) + '.png')" fit="cover"></el-image>
                  <div class="mentorIntroduce">
                    <div class="mentorName">{{item.name}}</div>
                    <div class="mentorPosition">{{item.text}}</div>
                  </div>
                  <div class="mentorIntroduce2">
                    <div class="companyName">{{item.companyName}}</div>
                    <div class="spot1"></div>
                    <div class="spot2"></div>
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </el-carousel-item>
    <!-- 预见独角兽峰会 -->
    <el-carousel-item class="moduleBox centerAlign">
      <div class="xinkeBox">
        <div class="xinkeFundTitle" @click="handleUrl('4')">预见独角兽峰会</div>
        <div class="xinkeFundIntroduce">
          <label>榜单发布</label>
          <label>政企对接</label>
        </div>
        <div class="moreBox" style="margin-top: 0;" @click="handleUrl('4')">
          <el-image class="moreImg" :src="require('@/assets/img/home/homeImg_9.png')" fit="cover"></el-image>
          <div class="moreBut">查看更多</div>
        </div>
        <div class="theMeetBox flex_between" style="margin-top: 4.88rem;">
          <el-image class="theMeetImg transition" :src="require('@/assets/img/home/summitImg_1.png')" fit="cover"></el-image>
          <el-image class="theMeetImg2 transition" :src="require('@/assets/img/home/summitImg_2.png')" fit="cover"></el-image>
          <el-image class="theMeetImg2 transition" :src="require('@/assets/img/home/summitImg_3.png')" fit="cover"></el-image>
        </div>
        <div class="theMeetBox flex_between">
          <el-image class="theMeetImg2 transition" :src="require('@/assets/img/home/summitImg_4.png')" fit="cover"></el-image>
          <el-image class="theMeetImg2 transition" :src="require('@/assets/img/home/summitImg_5.png')" fit="cover"></el-image>
          <el-image class="theMeetImg transition" :src="require('@/assets/img/home/summitImg_6.png')" fit="cover"></el-image>
        </div>
      </div>
    </el-carousel-item>
    <!-- 独角兽培育服务 -->
    <el-carousel-item class="moduleBox centerAlign">
      <div class="columnBack"></div>
      <div class="tvBox">
        <div class="tvTopInfo">
          <div class="tvTopLeft">
            <div class="tvTopTitleBox">
            <div class="tvTopTitle" @click="handleUrl('5')">独角兽培育服务</div>
            </div>
            <div class="tvIntroduce">
              <label>推动智慧政府</label>
              <label>科技产业对接</label>
            </div>
          </div>
          <div class="tvTopRight">
            <div class="tvTopText">
              依托“预见独角兽”丰富的项目资源及投资机构资源，以及培育创新企业、
              独角兽企业的成功经验到全国100个城市落地生根，助力地方城市产业
              创新升级，为政府、投资机构、产业园区、龙头企业开展对接，为政府
              部门高效管理赋能，为园区招商引智赋能，为企业创新转型赋能，助推
              科技产业发展，加速科技成果转化。
            </div>
          </div>
        </div>
        <div class="moreBox" @click="handleUrl('5')">
          <el-image class="moreImg" :src="require('@/assets/img/home/homeImg_9.png')" fit="cover"></el-image>
          <div class="moreBut">查看更多</div>
        </div>
        <el-row class="columnDataBox">
          <el-col :span="4" style="height: 100%;">
            <div class="columnDataLi borBom1">
              <el-image class="tvProgramaImg" :src="require('@/assets/img/home/cultivate_1.png')" fit="cover"></el-image>
              <div class="tvProgramaNum">
                <span>{{unicornInfo.dataNum0}}</span><span class="font_3" style="margin: 0;">项</span>
              </div>
              <div class="tvProgramaText">政府项目</div>
            </div>
          </el-col>
          <el-col :span="4" style="height: 100%;">
            <div class="columnDataLi2 borBom2 borRig">
              <el-image class="tvProgramaImg" :src="require('@/assets/img/home/cultivate_2.png')" fit="cover"></el-image>
              <div class="tvProgramaNum">
                <countTo class="font_1" :decimals="1" separator="" :startVal='0' :endVal='unicornInfo.dataNum1' :duration='2000'></countTo>
                <span class="font_3">亿</span>
                <!-- <span>7.1</span><span class="font_3">亿</span> -->
              </div>
              <div class="tvProgramaText">技术交易额</div>
            </div>
          </el-col>
          <el-col :span="4" style="height: 100%;">
            <div class="columnDataLi2 borBom1 borRig">
              <el-image class="tvProgramaImg" :src="require('@/assets/img/home/cultivate_3.png')" fit="cover"></el-image>
              <div class="tvProgramaNum">
                <countTo class="font_1" separator="" :startVal='0' :endVal='unicornInfo.dataNum2' :duration='2000'></countTo>
                <span class="font_3">项</span>
                <!-- <span>164</span><span class="font_3">项</span> -->
              </div>
              <div class="tvProgramaText">成果落地</div>
            </div>
          </el-col>
          <el-col :span="4" style="height: 100%;">
            <div class="columnDataLi2 borBom2 borRig">
              <el-image class="tvProgramaImg" :src="require('@/assets/img/home/cultivate_4.png')" fit="cover"></el-image>
              <div class="tvProgramaNum">
                <countTo class="font_1" separator="" :startVal='0' :endVal='unicornInfo.dataNum3' :duration='2000'></countTo>
                <span class="font_3">家</span>
                <!-- <span>1000</span><span class="font_3">家</span> -->
              </div>
              <div class="tvProgramaText">高校院所</div>
            </div>
          </el-col>
          <el-col :span="4" style="height: 100%;">
            <div class="columnDataLi2 borBom1 borRig">
              <el-image class="tvProgramaImg" :src="require('@/assets/img/home/cultivate_5.png')" fit="cover"></el-image>
              <div class="tvProgramaNum">
                <countTo class="font_1" separator="" :startVal='0' :endVal='unicornInfo.dataNum4' :duration='2000'></countTo>
                <span class="font_3">万+</span>
                <!-- <span>100</span><span class="font_3">万+</span> -->
              </div>
              <div class="tvProgramaText">科创资源</div>
            </div>
          </el-col>
          <el-col :span="4" style="height: 100%;">
            <div class="columnDataLi2 borBom2">
              <el-image class="tvProgramaImg" :src="require('@/assets/img/home/cultivate_6.png')" fit="cover"></el-image>
              <div class="tvProgramaNum">
                <countTo class="font_1" separator="" :startVal='0' :endVal='unicornInfo.dataNum5' :duration='2000'></countTo>
                <span class="font_3">家</span>
                <!-- <span>1000</span><span class="font_3">家</span> -->
              </div>
              <div class="tvProgramaText">培育瞪羚 <br/>（独角兽）企业</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-carousel-item>

    <!-- 预见独角兽 -->
    <el-carousel-item class="moduleBox centerAlign">
      <div class="tvBox">
        <div class="introduceDataBox flex_between">
          <div class="introduceDataInfo">
            <div class="dataTitle">平台项目</div>
            <div class="dataNum">
              <countTo class="font_1" separator="" :startVal='0' :endVal='introduceInfo.dataNum1' :duration='2000'></countTo>
              <span class="font_2">+</span>
              <!-- <ScrollNum :num="introduceInfo.dataNum1" text="+"></ScrollNum> -->
              <!-- <span class="font_1" style="letter-spacing: 1px;">10000</span><span class="font_2">+</span> -->
            </div>
          </div>
          <div class="introduceDataInfo">
            <div class="dataTitle">融资金额</div>
            <div class="dataNum">
              <countTo class="font_1" separator="" :startVal='0' :endVal='introduceInfo.dataNum2' :duration='2000'></countTo>
              <span class="font_2">亿</span>
              <!-- <span class="font_1">60</span><span class="font_2">亿</span> -->
            </div>
          </div>
          <div class="introduceDataInfo">
            <div class="dataTitle">管理基金</div>
            <div class="dataNum">
              <countTo class="font_1" separator="" :startVal='0' :endVal='introduceInfo.dataNum3' :duration='2000'></countTo>
              <span class="font_2">亿</span>
              <!-- <span class="font_1">100</span><span class="font_2">亿</span> -->
            </div>
          </div>
          <div class="introduceDataInfo">
            <div class="dataTitle">科创资源</div>
            <div class="dataNum">
              <countTo class="font_1" separator="" :startVal='0' :endVal='introduceInfo.dataNum4' :duration='2000'></countTo>
              <span class="font_2">万</span>
              <!-- <span class="font_1">100</span><span class="font_2">亿</span> -->
            </div>
          </div>
        </div>
        <div>
          <div class="introduceRight backActive">
            <!-- <video class="videoAvatar" autoplay muted ref="myVideo" src="https://file.thinkervc.cn/%E4%BB%80%E4%B9%88%E6%98%AF%E9%A2%84%E8%A7%81%E7%8B%AC%E8%A7%92%E5%85%BD--%E8%A7%86%E9%A2%91%E4%BB%8B%E7%BB%8D.mp4" controls="controls">您的浏览器不支持视频播放</video> -->
            <video class="videoAvatar" ref="myVideo" src="https://djs.thinkerwork.com/%E8%A7%86%E9%A2%91/%E5%BF%83%E5%AE%A2%20%E9%A2%84%E8%A7%81%E7%8B%AC%E8%A7%92%E5%85%BD.mp4" controls="controls">您的浏览器不支持视频播放</video>
          </div>
          <div class="introduceLeft">
            <div class="introduceRightBox">
              <div class="introduceTitle">预见独角兽</div>
              <div class="introduceTitle2">预见未来创新领袖</div>
              <div class="introduceText">
                预见独角兽是中国领先的创投平台，以“成为最受尊敬的创投平台”为愿景，
                以“预见未来创新领袖”为使命，已服务企业超100000家。知名投资人、专
                家、导师库超10000人。旗下基金覆盖企业早、中、后期各个阶段，同时
                与梅花创投、红杉中国等超百家国内优秀投资机构达成联合投资深度合作，
                已帮助近百个项目获得融资超30亿元。与东方卫视和江西卫视联袂打造的
                《预见独角兽》节目、一年一度的全国创投峰会、独角兽特训营正在帮助
                越来越多优秀的创业者成为伟大的企业家。
              </div>
            </div>
          </div>
        </div>
      </div>
      </el-carousel-item>

      <!-- 合作查询 -->
      <el-carousel-item class="ruleBox centerAlign">
        <div class="xinkeBox">
          <div class="xinkeFundTitle">合作查询</div>
          <div class="xinkeFundIntroduce" style="margin-bottom: 50px;">
            <label>传承典范</label>
          </div>
          <el-row :gutter="40" style="width: 100%;">
            <el-col :span="8">
              <div class="teamworkLi">
                <div class="teamworkImg"><el-image :src="teamwork1" fit="fill" style="width: 100%;height: 100%;cursor: pointer;"></el-image></div>
                <div class="teamworkInfo">
                  <div class="teamworkName">工作人员查询</div>
                  <div class="teamworkVal"><el-input v-model="queryVal1" placeholder="输入工作人员"></el-input></div>
                  <div class="teamworkBut" @click="queryInfoFun(1)">查询</div>
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="teamworkLi">
                <div class="teamworkImg"><el-image :src="teamwork2" fit="fill" style="width: 100%;height: 100%;cursor: pointer;"></el-image></div>
                <div class="teamworkInfo">
                  <div class="teamworkName">合同查询</div>
                  <div class="teamworkVal"><el-input v-model="queryVal2" placeholder="输入合同编号"></el-input></div>
                  <div class="teamworkBut" @click="queryInfoFun(2)">查询</div>
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="teamworkLi">
                <div class="teamworkImg"><el-image :src="teamwork3" fit="fill" style="width: 100%;height: 100%;cursor: pointer;"></el-image></div>
                <div class="teamworkInfo">
                  <div class="teamworkName">服务机构查询</div>
                  <div class="teamworkVal"><el-input v-model="queryVal3" placeholder="输入服务机构名称"></el-input></div>
                  <div class="teamworkBut" @click="queryInfoFun(3)">查询</div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-carousel-item>
    </el-carousel>



    <!-- 手机端 -->
    <div class="mobileH5Box">
      <!-- <div style="height: 58px;"></div> -->
      <el-carousel class="mobileCarouselBox" height="229px" indicator-position="none" :interval="4500">
        <el-carousel-item style="height: 100%;" @click.native="appletUrl('3')">
          <div style="height: 229px;">
            <el-image :src="carouselItemImg1" fit="fill" style="width: 100%;height: 100%;cursor: pointer;"></el-image>
          </div>
        </el-carousel-item>
        <el-carousel-item style="height: 100%;" @click.native="appletUrl('4')">
          <div style="height: 229px;">
            <el-image :src="carouselItemImg2" fit="fill" style="width: 100%;height: 100%;cursor: pointer;"></el-image>
          </div>
        </el-carousel-item>
        <el-carousel-item style="height: 100%;" @click.native="appletUrl('')">
          <div style="height: 229px;">
            <el-image :src="carouselItemImg3" fit="fill" style="width: 100%;height: 100%;cursor: pointer;"></el-image>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="mobileH5Content">
        <div class="programaContent flex_between" @click="handleUrl('1')">
          <div class="flex_between" style="height: 100%;flex-direction: column;display: flex;">
            <div class="text13">
              <span class="fontBold">上卫视</span>
              <span>《预见独角兽》栏目</span>
            </div>
            <div class="text12" style="color: #a4a4a4;">知名主持人 创投大咖</div>
            <div class="text12" style="color: #a4a4a4;">头部流量达人 加盟渠道共同助力</div>
          </div>
          <div class="flex_between">
            <el-image
              fit="fill"
              src="http://file.thinkervc.cn/u6tppjfoo4kdv57mbvssexwn98mwo7ptylk5ma046s0nyvn1ewaas9gt9ihv57zv3zyz9zemcs300909.png"
              style="width: 55px;height: 55px;margin-right: 10px;"
            ></el-image>
            <!-- <el-image
              fit="fill"
              src="http://file.thinkervc.cn/nql7yj0oozkj3iumr1kmmxhsdr4u6o58jj9l30lqfrdsk7e3iv93345aa9l191ni8qf399kn6veejglh.png"
              style="width: 55px;height: 55px;"
            ></el-image> -->
          </div>
        </div>
        <div class="investBox flex_between">
          <div class="flex_between" style="height: 100%;align-items: center;"  @click="appletUrl('1')">
            <div class="flex_between" style="height: 80%;flex-direction: column;display: flex;">
              <span class="text16">找投资</span>
              <span class="text12" style="letter-spacing: -1px; color: #a4a4a4;">知名风投 一触即达</span>
            </div>
            <div class="flex_between" style="width: 44px;flex-direction: column;display: flex;">
              <span class="fontBold" style="color: #FF9834;">10000+</span>
              <span class="text10" style="color: #A4A4A4;white-space: nowrap;margin-bottom: 10px;">认证投资人</span>
            </div>
          </div>
          <div class="marginLr"></div>
          <div class="flex_between" style="height: 100%;" @click="appletUrl('2')">
            <div class="flex_between" style="height: 80%;flex-direction: column;display: flex;">
              <div class="text16">找项目</div>
              <div class="text12" style="letter-spacing: -1px; color: #a4a4a4;">投资项目 加盟品牌</div>
            </div>
            <div class="flex_between" style="width: 44px;flex-direction: column;display: flex;align-items: center;">
              <span class="fontBold" style="color: #FF9834;">100000+</span>
              <span class="text10" style="color: #A4A4A4;white-space: nowrap;margin-bottom: 12px;">优质项目</span>
            </div>
          </div>
        </div>
        <div class="gridH5Box">
          <div v-for="(item, index) in naviBtns" :key="index" class="gridH5Li flex_between" @click="handleUrl(item.name)">
            <div class="flex_between" style="height: 100%;flex-direction: column">
              <span class="text14" :style="{ 'letter-spacing': index == 3 ? '-1px' : 0 }">{{ item.title }}</span>
              <span class="text12" style="width: 115px; color: #a4a4a4; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ item.desc }}</span>
            </div>
            <el-image fit="fill" :src="item.image" style="width: 29px;height: 29px;"></el-image>
          </div>
        </div>
      </div>
    </div>


    <!-- 查询弹框-->
    <el-dialog title="" :visible.sync="queryOpen" width="80%">
      <div class="dialogTitle">查询结果</div>
      <div style="width: 100%;padding: 0 20px;">
        <!-- 工作人员查询 -->
        <el-table border :data="queryList" v-if="queryType == 1">
          <el-table-column label="图片" align="center" prop="image" width="100">
            <template slot-scope="scope">
              <image-preview :src="scope.row.image" :width="50" :height="50" />
            </template>
          </el-table-column>
          <el-table-column label="姓名" align="center" prop="name" />
          <el-table-column label="职位" align="center" prop="post" />
          <el-table-column label="所属区域" align="center" prop="area" />
          <el-table-column label="工号" align="center" prop="jobNumber" />
        </el-table>

        <!-- 合同查询 -->
        <el-table border :data="queryList" v-if="queryType == 2">
          <el-table-column label="合同名称" align="center" prop="name" />
          <el-table-column label="合同编号" align="center" prop="number" />
          <el-table-column label="甲方公司" align="center" prop="partyA" />
          <el-table-column label="乙方公司" align="center" prop="partyB" />
          <el-table-column label="合同金额" align="center" prop="money" />
          <el-table-column label="签约人员" align="center" prop="signAContract" />
        </el-table>

        <!-- 服务机构查询 -->
        <el-table border :data="queryList" v-if="queryType == 3">
          <el-table-column label="图片" align="center" prop="imgUrl" width="100">
            <template slot-scope="scope">
              <image-preview :src="scope.row.imgUrl" :width="50" :height="50" />
            </template>
          </el-table-column>
          <el-table-column label="联系人" align="center" prop="userName" />
          <el-table-column label="职位" align="center" prop="position" />
          <el-table-column label="机构名称" align="center" prop="companyName" />
          <el-table-column label="投资领域" align="center" prop="investmentField" />
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { request } from '@/utils/request';
import wx from 'weixin-js-sdk';
import ScrollNum from "@/components/ScrollNum/ScrollNum.vue";
import countTo from 'vue-count-to';
import { debounce } from "@/utils/util"
// 图片预览组件
import ImagePreview from "@/components/ImagePreview"
export default {
  name: 'Home',
  components: {
    countTo,
    ImagePreview
  },
  data() {
    return {
      autoplay: true,
      qrcodeType: '',
      centerDialogVisible: false,
      mobileCarouselImg: "http://file.thinkervc.cn/269k17bc3khlo023wjk3vi432s62mesbg69iz6ubn7n88p317lkjq74bsy0zdzbq10igxu6xvtv7z6kn.png",
      mobileCarouselImg2: "http://file.thinkervc.cn/tjqpwhk5yie4etbeq0tdeh8vpil4f5pq4p26ur40s4swmy1nal14yphdqmgj47igxlf45a623clo5ye4.png",
      bannerList: [
        { id: "1", picture: "http://file.thinkervc.cn/k8rpmw9fz6oa04e2lfr8s6ghq21spkbtiqpe61jmpwryzl7wxups0oxf3t3nd0b789sb9upsfxgkew5c.png", redirectUrl: "" },
        { id: "2", picture: "http://file.thinkervc.cn/16q2433bxdl1amggz412s1xx1nsvkstbp5susmtfxun9ab5uylmtem2gfz81749lmekrg5wn0fo6mq1e.jpg", redirectUrl: "" },
        { id: "3", picture: "http://djs.thinkerwork.com/20230710170055276_未标题-1(1).jpg", redirectUrl: "" }
      ],
      bannerList2: [
        { id: "1", type: "8", picture: "http://file.thinkervc.cn/269k17bc3khlo023wjk3vi432s62mesbg69iz6ubn7n88p317lkjq74bsy0zdzbq10igxu6xvtv7z6kn.png", redirectUrl: "" },
        { id: "2", type: "3", picture: "http://file.thinkervc.cn/im29djzy6paan0bxhliz8humftpokvhkmodeiazaramqed3id53mbqjegee2ghl65i90zqg1nzbf95u9.png", redirectUrl: "" }
      ],
      carouselItemImg1: "http://file.thinkervc.cn/269k17bc3khlo023wjk3vi432s62mesbg69iz6ubn7n88p317lkjq74bsy0zdzbq10igxu6xvtv7z6kn.png",
      carouselItemImg2: "https://djs.thinkerwork.com/20241113165832795_cshyz.jpg",
      carouselItemImg3: "http://djs.thinkerwork.com/20230710170055276_未标题-1(1).jpg",
      introduceInfo: {
        dataNum1: 0,
        dataNum2: 0,
        dataNum3: 0,
        dataNum4: 0
      },
      tvInfo: {
        dataNum0: 0,
        dataNum1: 0,
        dataNum2: 0,
        dataNum3: 0,
        dataNum4: 0,
        dataNum5: 0
      },
      unicornInfo: {
        dataNum0: 0,
        dataNum1: 0,
        dataNum2: 0,
        dataNum3: 0,
        dataNum4: 0,
        dataNum5: 0
      },
      enterpriseList: [
        { id: "1", img: "http://file.thinkervc.cn/wbfcxgslfm93d3v46eufgob3fnv4zcjf1zahainglgeysj4ay7752p5bhgmrjrc90wzukiiovw1aiht4.png" },
        { id: "2", img: "http://file.thinkervc.cn/4dav0kwskf31suh92853zxiyxlm9o8njnecefkhocb03w9hhml8pgmvw3m6316utn6l5mke60ta509ce.png" },
        { id: "3", img: "http://file.thinkervc.cn/tffydq1xkabnrr6brscck29q2rutwknjd1oklu486le7pdj3ohkqqz7kflq8mid0sfag185v68aqlltz.png" },
        { id: "4", img: "http://file.thinkervc.cn/imaers2p0w543odl6a34fbyht5e4c2a05zfmuqvt0xbgzlu7vgjn21egg77uaxadvhjwaxszceygilsr.png" },
        { id: "5", img: "http://file.thinkervc.cn/85v7s80kcfr4o6xfzhcp6x2cfgmg0bq5hxrhx1ybvf9sks5goo9qjj33xvbaukkl531vfwqlaze1npe4.png" },
        { id: "6", img: "http://file.thinkervc.cn/mc89jz9yfcwarfvjove5uootm496y4qjo81n1hn6vsy2qb219c0q1nfv48ibvhv70n5plzjnfsmau1vm.png" },
        { id: "7", img: "http://file.thinkervc.cn/axuf93fa51ihqsat1xv1ti9ingek2vk6lagve8v0irxupyp8rg9sg108321fmo4eue8f243ucrv2cq9y.png" },
        { id: "8", img: "http://file.thinkervc.cn/b40pc4h95vwf9ux5hlcsi925t3yziq5l9pqfunvipb3s9konnhi86ozkx0xb63x7zjdeicnpizdbim3t.png" },
        { id: "9", img: "http://file.thinkervc.cn/wd2dacbvyi9ztsfm32zraig9p9h2ra97ji6qwtk2wrqna98to5l4v2apv1jvki7e9cyrc8g3qx2caeys.png" },
        { id: "10", img: "http://file.thinkervc.cn/mw2g64bim9185qd2wn3767v7quszxbugaqnrswctpi5jfwcx6f0ff9aslebawa7528muww7wuqlkipuc.png" }
      ],
      inx: 1,
      TextList: [
        { id: "1", name: "江西赣锋锂业集团股份有限公司", text: "赣锋锂业是全球第三大及中国最大的锂化合物生产商及全球最大的金属锂生产商。公司专业从事各种锂产品的研发、生产与经营，涉及锂矿开采、锂资源提取、锂盐产品深加工、金属锂冶炼与提纯、锂电池制造、锂电池回收等产业链，产品涵盖金属锂、碳酸锂、氢氧化锂、丁基锂、锂离子电池等五大类四十多种，是目前国内锂行业产品种类最齐全、产业链最完善的企业。坚持科技创新，是公司发展的源动力。拥有国家级企业技术中心、国家博士后科研工作站、国家地方联合工程研究中心和院士工作站。" },
        { id: "2", name: "江西华伍制动器股份有限公司简介", text: "江西华伍制动器股份有限公司主要从事工业制动器及其控制系统的研发、设计、制造和销售。公司是目前国内生产规模大、产品品种全、行业覆盖面广,并具备较强自主创新能力的工业制动器专业生产商和工业制动系统解决方案提供商。产品大量广泛应用于港口、轨道交通、风力发电、冶金、矿山及建筑机械、水利电力工程、船舶及海洋重工、石油钻采等行业,并大量配套出口到全球92个国家和地区。" },
        { id: "3", name: "崇义章源钨业股份有限公司简介", text: "崇义章源钨业股份有限公司位于“世界钨都”——江西省赣州市，历经二十余载的艰辛创业，从一家采选矿为主的小企业迅速发展成为集钨的采选、冶炼、制粉与硬质合金生产和深加工、贸易为一体的集团型企业。拥有6座采矿权矿山，8个探矿权矿区，根据公司在江西省自然资源厅最新矿权储量评审备案，公司所辖矿权保有钨资源储量9.01万吨，为构筑雄厚的钨资源保障基地奠定了扎实的基础。" },
        { id: "4", name: "理想汽车简介", text: "理想汽车是中国新能源汽车市场的领导者，是成功将增程式电动车商业化的先驱，理想汽车的使命是创造移动的家，创造幸福的家。通过产品、技术和业务模式的创新，为家庭用户提供安全、便捷、舒适的产品与服务。公司自主研发工作主要集中在其特有的增程系统，下一代电动汽车技术以及智能汽车解决方案，同时推出更多纯电动车和增程式电动车以扩展产品线，进而拓宽用户群体。" },
        { id: "5", name: "科比特航空科技（广东）有限公司简介", text: "科比特航空科技（广东）有限公司创建于2010年，作为国际领先的工业级无人机企业，科比特航空先后兼并收购了上下游数十家团队企业，率先打造了全球首条工业级无人机系统产业链，同时科比特作为行业标准化建设的践行者和推动者，牵头制定了多项无人机国际标准，引领了国际工业级无人机行业标准制定。在此基础上，科比特航空每年以数倍营收增长而抢先占有市场份额，成为工业级无人机龙头企业。            " },
        { id: "6", name: "北京码牛科技股份有限公司简介", text: "北京码牛科技股份有限公司主要从事基于大数据的人工智能行业解决方案，以及对应方案的软件和硬件销售。公司产品核心价值的实现，体现为嵌入式软件、基础软件和应用软件的研发、生产、销售和技术服务。" },
        { id: "7", name: "诺客环境科技集团有限公司简介", text: "诺客环境科技集团有限公司是一家集投资、设计、建设及运营为一体的以水泥窑协同处置危险废弃物、污染土处理为主的，其他无害化、资源化为辅的危废一站式服务提供商。截至目前，公司取证项目14个，合计处置能力80余万吨/年，位于11个省市。分别位于河南、湖北、内蒙古、辽宁、吉林、贵州、四川、广西、广东、新疆、江西等地。" },
        { id: "8", name: "江西怡杉环保股份有限公司简介", text: "江西怡杉环保股份有限公司 是一家以环境监测设备系统及系统集成、环境污染治理、第三方运维的一体化环境保护服务商。目前，公司形成了以重金属环保业务与常规环保业务两大业务为主的架构，为客户提供环境监测设备、第三方服务以及环境污染治理工程服务。" },
        { id: "9", name: "新余博迅汽车有限公司简介", text: "新余博迅汽车有限公司的主营业务为专用车的生产、销售，产品主要为1吨至3.8吨消防汽车、无人机及无人机指挥车、消防机器人、智慧消防物联网系统、微型消防站等。公司产品主要面向消防大队、园区和企业。公司生产采用“以销定产”的模式，即根据订单情况来确定生产计划及组织安排生产活动。" },
        { id: "10", name: "北京猎户星空科技有限公司简介", text: "北京猎户星空科技有限公司重点发展TO B端智能服务机器人业务，为客户提供场景应用解决方案，目前主要业务为智能服务机器人的研发、销售及租赁。在行业内率先提出“AI+软件+硬件+服务=机器人”的公式。在底层算法上，自研全链条AI技术涵盖远端噪音消除算法、智能语音识别算法、智能人脸识别算法、视觉和激光混合识别的室内导航算法、云端大数据算法；在软件层，基于开源安卓打造了全自研导航技术及语音OS、Robot OS开放系统；在硬件层，拥有服务机器人全自研标准化硬件研发体系，具备整机设计、结构、供应链整合和生产的全栈能力；在服务层，具备全自研云端大脑服务能力，包括与行业伙伴实现各场景解决方案、云端实现远程控制和配置、全国网点服务等能力。猎户星空已推出接待服务机器人、递送服务机器人、消毒服务机器人、新零售服务机器人等在内的“智能服务机器人家族”，与行业合作伙伴构建机器人生态，推动AI和5G时代实体经济和场景的智能化升级。" }
      ],
      trainingImg: "http://file.thinkervc.cn/f43lmi4iypwcbln6msyzucvsz1dc8udk23sq5cvyxqncffwagk1ggkuby1ob9upu0joijpg2w24ezbew.png",
      trainingImg2: "http://file.thinkervc.cn/0pzeavy5ps3wzpz52ej9t8nl3q7ni0rnsgew15pf9su4tlip8bfk96rdxw4m937cupg1uttaab4nw6od.png",
      mentorList: [
        {
          id: "1",
          list: [
            { id: "1", name: "傅盛", text: "董事长", companyName: "猎户星空" },
            { id: "2", name: "吴世春", text: "创始合伙人", companyName: "梅花创投" },
            { id: "3", name: "周逵", text: "合伙人", companyName: "红杉资本" },
            { id: "4", name: "朱啸虎", text: "董事总经理", companyName: "金沙江创投" },
            { id: "5", name: "江南春", text: "创始人", companyName: "分众传媒" }
          ]
        }
      ],
      flag: true,
      carouselIndex: 0,
      naviBtns: [
        {
          name: '3',
          title: '独角兽训练营',
          desc: '名师带徒 名企游学',
          image: 'http://file.thinkervc.cn/yzd67dhni43b794r3yvhlwi4tv7hu4yqywn72cdij52itnhkqysm7mbnstvg9jnsam7thux04dd2kkfy.png'
        },
        {
          name: '4',
          title: '预见独角兽峰会',
          desc: '榜单发布 政企对接',
          image: 'http://file.thinkervc.cn/pjgz0xd83loldpu4t3zn9ntkrsqq0r6dc9qfe1nb8ipfhf5dwlt8hx5zl2i5oqu8fuuduw5vckdg3yxv.png'
        },
        {
          name: '5',
          title: '独角兽培育服务',
          desc: '政务服务 成果转化 ',
          image: 'http://file.thinkervc.cn/s9nm9blya9bwe6ghkld6os2r1337n6wzxf08fxho4h6ejtyy61ulj2orhj09dgwsous557s7lao15r59.png'
        },
        {
          name: '8',
          title: '全国城市海选站',
          desc: '上海 深圳 杭州 苏州 ',
          image: 'http://file.thinkervc.cn/c3d10dnagn1az8azercgb2g3g446g1fq8axxm9cc67cvg6d5jdgrpv18ovkvk8dfqrmx7qscbhjbhu41.png'
        },
        {
          name: '6',
          title: '最新活动',
          desc: '精彩活动不容错过',
          image: 'http://file.thinkervc.cn/9esf5pgwi1jrknyqhifnx86hbr23ymlzzl5ra8qa7fi8c7f0nvd82ndajzpljkychqkiz5hxcuz3rqf3.png'
        },
        {
          name: '7',
          title: '创投前沿',
          desc: '创投资讯实时查看',
          image: 'http://file.thinkervc.cn/grczek2zr4vcu5og0ygzrlq1bawi9sgwka0hl3697ikeerisyyussq23uyt4mqtutnj8ois5ninxd6yl.png'
        }
      ],
      //规则
      ruleImg1:"http://file.thinkervc.cn/b8gyhx8e72xrevv7mztclto6hif544gtxfcw9e1ffzxq55n7vfzt86yx59tsi7wnwiz6vsnp4e7pu1x9.png",
      ruleImg2:"http://file.thinkervc.cn/w4oqfzub150asdshtt187y9n9p41c63kckmt5a7sisyagxwdhcar697m5octe13b72m81bkd3gwieyde.png",
      ruleImg3:"http://file.thinkervc.cn/it2tcoydk0rcotshh25l0qsg55bzmausrv0w5wf58pwwiwn2kqibqoo5hiywiqngzb6nx2llng2qk90f.png",
      //合作查询
      queryVal1:"",
      queryVal2:"",
      queryVal3:"",
      teamwork1:"http://file.thinkervc.cn/hfnqatin9k7v48m4o5ycfrvhqmti3aus2p2in49qd52e75v547zn7hd7rh479bj3q32mxjpnu1fni8v4.png",
      teamwork2:"http://file.thinkervc.cn/dnuedgwn1fjhovt4v5yt991nml5idwar8igvmp2rd00i1xhxgfq3v1f0tf5354ueofggc8fiilpewpa4.png",
      teamwork3:"http://file.thinkervc.cn/mc4uixhal3mzybx9o82kijgxrvdx0297gwcklyv3aoznznhhyc20qmccxhyb6lbyhrxcze81d0qbje8l.png",
      //新闻列表
      journalismList:[],
      initialIndex:0,
      //查询弹框
      queryOpen:false,
      queryType:0,
      queryList:[]
    };
  },
  mounted() {
    window.addEventListener('mousewheel', debounce(this.handleScroll), false);
    window.addEventListener('DOMMouseScroll', debounce(this.handleScroll), false);
  },
  created() {
    this.carouselIndex = 0;
    document.documentElement.scrollTop = 0;
    this.getList();
    this.getColumn();
  },
  methods: {
    /* 新闻资讯列表 */
    getList(){
      request('https://xinke.thinkervc.cn/api/web/activityCms/getActivityCmsList?pageNum=' + 1 + '&pageSize=' +15,{},{method: 'get'}).then(res => {
        if (res.code === 200) {
         this.journalismList = res.data.records;
        }
      });
    },
    //预见独角兽卫视栏目
    getColumn(){
      this.tvInfo = {
        dataNum0: 0,
        dataNum1: 0,
        dataNum2: 0,
        dataNum3: 0,
        dataNum4: 0,
        dataNum5: 0
      };
      this.unicornInfo = {
        dataNum0: 0,
        dataNum1: 0,
        dataNum2: 0,
        dataNum3: 0,
        dataNum4: 0,
        dataNum5: 0
      };
      request('/visitor/unicornManageInfo/getUnicornManageInfoMap').then(res => {
        if (res.code === 200) {
          let columnData = res.data["预见独角兽卫视栏目"];
          this.tvInfo = {
            dataNum0: columnData[0].synopsis,
            dataNum1: columnData[1].synopsis,
            dataNum2: columnData[2].synopsis,
            dataNum3: columnData[3].synopsis,
            dataNum4: columnData[4].synopsis,
            dataNum5: columnData[5].synopsis
          };
          this.unicornInfo = {
            dataNum0: columnData[6].synopsis,
            dataNum1: columnData[7].synopsis,
            dataNum2: columnData[8].synopsis,
            dataNum3: columnData[9].synopsis,
            dataNum4: columnData[10].synopsis,
            dataNum5: columnData[11].synopsis
          };
        }
      });
    },
    /* 时间转成年月日 */
    formatDate(dateStr) {
      const date = new Date(dateStr);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    /* 轮播图下标*/
    carouselHand(e){
      this.initialIndex = e;
    },
    /* 新闻跳转*/
    newsInfo(key,id){
      switch (key) {
        case "1":
          this.$router.push({ name: "News", query: {} });
          break;
        case "2":
          this.$router.push({ name: "NewsDetails", query: {id: id} });
          break;
        default:
          break;
      }
    },
    /* 底部查询 */
    queryInfoFun(type){
      this.queryType = type;
      var url = "";
      var name = "";
      if(type == 1){
        this.queryVal1 = this.queryVal1.replace(/\s*/g, "")
        if (this.queryVal1.length < 1) {
            this.$message.warning('请输入工作人员名字')
            return
        }
        url = "https://xinke.thinkervc.cn/api/web/activityCms/getPersonnelSystemByName?name=";
        name = this.queryVal1;
      }else if(type == 2){
        this.queryVal2 = this.queryVal2.replace(/\s*/g, "")
        if (this.queryVal2.length < 1) {
            this.$message.warning('请输入输入合同编号')
            return
        }
        url = "https://xinke.thinkervc.cn/api/web/activityCms/getContractInfo?name=";
        name = this.queryVal2;
      }else{
        this.queryVal3 = this.queryVal3.replace(/\s*/g, "")
        if (this.queryVal3.length < 1) {
            this.$message.warning('请输入输入服务机构名称')
            return
        }
        url = "https://xinke.thinkervc.cn/api/web/activityCms/getActivityInstitutions?name=";
        name = this.queryVal3;
      }
      request(url + name,{},{method: 'get'}).then(res => {
        if (res.code === 200) {
          this.queryList = res.data;
        }
      });
      this.queryOpen = true;
    },
    /* 跳转对应页面 */
    appletUrl(key) {
      switch (key) {
        case "1":
          this.$router.push({ name: "mobileInvestment", query: {} });
          break;
        case "2":
          this.$router.push({ name: "CommunityResources", query: {} });
          break;
        case "3":
          window.location.href = "https://xinke.thinkervc.cn/alias/#/auditionRegister?token=54a9c41ade66abdd82a8c06b7ed7b711b059bf3073e709be81019dac07f3214e5590b883182a34de8ca83d9ceb1abdfaf0827997e4adf8943f9e82e7277d55d96916e944f454ef673eb6a2bc4d35024304879b4d4a81bf803c9e0c4b201a165d9747b87a1b4857b5c177ae57a26146b7333663f9fabb16b3358da6f25f83bcb5a98bbbbc52d7c81fb2c086141432af1df503e393b19439770812ea4cc71bdfae0043fb6ae26e4ccd449db31af9a363589f1676e2ef6b367f9861375430b37327530c274889ee048ea6c0c870353f18197e9ac84557fb23370a1e68c4bf6fbd7c2cedc23c2d525294d10a223de5c8f840a9f277035b26065fe01e9fdd55fd2d27&openId=oPuiF4gOtyD9GlSNmc6-8sYAN1aE";
          break;
        case "4":
          this.$router.push({ name: "latestActivityDetail", query: {id: "54", type: "1"} });
          break;
        default:
          break;
      }
    },
    /** 监听鼠标滑轮滚动==正值为向下滚动，负值为向上滚动 **/
    handleScroll(e) {
      var title = this.$route.meta.title;
      if (title !== "首页") {
        return
      }
      if (this.flag === true) {
        var scrollTop = document.documentElement.scrollTop;
        if (e.wheelDelta) {
          if (e.wheelDelta < 0) {
            if (this.carouselIndex < 8) {
              this.carouselIndex = this.carouselIndex + 1;
              this.$refs.carouselRef.setActiveItem(this.carouselIndex);
            } else {
              document.documentElement.scrollTop = "500";
            }
          } else if (e.wheelDelta > 0) {
            if (scrollTop > 10) {
              document.documentElement.scrollTop = "0";
              return
            }
            if (this.carouselIndex > 0) {
              this.carouselIndex = this.carouselIndex - 1;
              this.$refs.carouselRef.setActiveItem(this.carouselIndex);
            }
          }
        } else if (e.detail) {
          if (e.detail > 0) {
            if (this.carouselIndex < 6) {
              this.carouselIndex = this.carouselIndex + 1;
              this.$refs.carouselRef.setActiveItem(this.carouselIndex);
            } else {
              document.documentElement.scrollTop = "500";
            }
          } else if (e.detail < 0) {
            if (scrollTop > 10) {
              document.documentElement.scrollTop = "0";
              return
            }
            if (this.carouselIndex > 0) {
              this.carouselIndex = this.carouselIndex - 1;
              this.$refs.carouselRef.setActiveItem(this.carouselIndex);
            }
          }
        }
        if (this.carouselIndex !== 1) {
          this.$refs.myVideo.pause();
        }
        if (this.carouselIndex === 1) {
          //预见独角兽卫视栏目
          this.getColumn();
        } else if (this.carouselIndex === 6) {
          //独角兽培育服务
          this.getColumn();
        } else if (this.carouselIndex === 7) {
          this.introduceInfo = {
            dataNum1: 10000,
            dataNum2: 60,
            dataNum3: 100,
            dataNum4: 100
          }
        }
      }
      this.flag = false;
      setTimeout(() => {
        this.flag = true;
      }, 800)
    },
    /** 页面跳转 **/
    handleUrl(key, keyPath) {
      switch (key) {
        case "1":
          this.$router.push({ name: "MyTV", query: {} });
          break;
        case "2":
          this.$router.push({ name: "Fund", query: {} });
          break;
        case "3":
          this.$router.push({ name: "UnicornAcademy", query: {} });
          break;
        case "4":
          this.$router.push({ name: "Summit", query: {} });
          break;
        case "5":
          this.$router.push({ name: "IncubationService", query: {} });
          break;
        case "6":
          this.$router.push({ name: "latestActivity", query: {} });
          break;
        case "7":
          this.$router.push({ name: "VCFrontier", query: {} });
          break;
        case "8":
          this.$router.push({ name: "CityAudition", query: {} });
          break;
        default:
          break;
      }
    },
    poupClose() {
      console.log('centerDialogVisible = false')
      this.centerDialogVisible = false
    },
    poupOpen() {
      console.log('centerDialogVisible = false')
      this.centerDialogVisible = true
    }
  }
};
</script>
<style lang="scss" scoped>
.containers{
  margin-top: 2px;
}
#containers{
  overflow: hidden;
}
/* 去除默认样式 */
a {
  text-decoration: none;
  color: #333;
}
a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:hover {
  color: #f08519;
  text-decoration: none;
}
a:active {
  text-decoration: none;
}
::v-deep .el-carousel__indicators--horizontal {
  left: auto !important;
  bottom: 1% !important;
  right: 2% !important;
  transform: translateX(0) !important;
}
::v-deep .el-row {
  margin-right: 0;
}
/* 修改弹窗样式 */
::v-deep .el-dialog {
  height: 30rem;

  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    padding: 0;
  }
}
.verticals{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.transition{transition: .5s;-moz-transition: .5s;-webkit-transition: .5s;-o-transition: .5s;-ms-transition: .5s;}
/* 两端对齐，项目之间的间隔都相等 */
.flex_between {
	display: flex;
	justify-content: space-between;
}
.centerAlign{
	display: flex;
	align-items: center;
}
.line1 {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.line4{
	overflow:hidden;
	text-overflow:ellipsis;
	white-space: normal;
	display:-webkit-box;
	-webkit-box-orient:vertical;
	-webkit-line-clamp:4;
}
.line5{
	overflow:hidden;
	text-overflow:ellipsis;
	white-space: normal;
	display:-webkit-box;
	-webkit-box-orient:vertical;
	-webkit-line-clamp:5;
}
.line15{
	overflow:hidden;
	text-overflow:ellipsis;
	white-space: normal;
	display:-webkit-box;
	-webkit-box-orient:vertical;
	-webkit-line-clamp:13;
}
.carouselContainers{
  position: relative;
  overflow: hidden;
  height: 100vh;
  display: block;
}
.mobileH5Box{
  display: none;
}
.carouselBox{
  width: 100%;
  // height: calc(100vh - 60px);
  height: 100vh;
}
.moduleBox{
  width: 100%;
  height: 100vh;
}
.introduceLeft{
  float: left;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.introduceRight{
  float: right;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
}
.introduceRightBox{
  overflow: hidden;
}
.videoAvatar{
  width: 40.44rem;
  height: 24rem;
}
.introduceTitle{
  height: 3.5rem;
  font-size: 2.5rem;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: left;
  color: #333333;
  line-height: 3.5rem;
  letter-spacing: 0.04rem;
}
.introduceTitle2{
  height: 1.5rem;
  font-size: 1.2rem;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: left;
  color: #8d8d8d;
  margin-top: 1.13rem;
  line-height: 1.5rem;
}
.introduceText{
  width: 39.88rem;
  font-size: 1rem;
  font-family: Source Han Sans CN, Source Han Sans CN-Normal;
  font-weight: normal;
  text-align: justify;
  text-indent: 2em;
  color: #333333;
  margin-top: 4.13rem;
  line-height: 2rem;
}
.introduceDataBox{
  width: 100%;
  // border-top: 1px solid #e5e5e5;
  overflow: hidden;
  margin: 2rem 0 7rem 0;
}
.introduceDataInfo{
  width: 14.32rem;
  overflow: hidden;
}
.dataTitle{
  height: 1rem;
  font-size: 1.2rem;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: left;
  color: #000000;
  position: relative;
  line-height: 1rem;
}
.dataTitle::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 0.94rem;
  height: 0.94rem;
  border-top: 0.06rem solid #f08307;
  border-right: 0.06rem solid #f08307;
}
.dataNum{
  font-size: 2.94rem;
  font-family: DIN, DIN-Bold;
  font-weight: 700;
  text-align: left;
  color: #000000;
  letter-spacing: 0.24rem;
  margin-top: 0.7rem;
  position: relative;
}
.dataNum::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 100%;
    height: 2.71rem;
    background: linear-gradient(180deg,#ffead4, #ffae51);
    z-index: 1;
}
.font_1{
  position: relative;
  z-index: 2;
  letter-spacing: 1px;
}
.font_2{
  position: relative;
  z-index: 2;
  font-size: 1.63rem;
  font-weight: 500;
  margin-left: -12px;
}
.columnBack{
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  width: 34.81rem;
  height: 100%;
  background-image: url("http://file.thinkervc.cn/825dchoro8qogzzl3c3aha274exh19zr9l49kdigaxlw0fvd1c5v627rui4c46xn58lxc3jt5ica6rb1.png");
  background-size: 100% 100%;
}
.tvBox{
  position: relative;
  z-index: 2;
  // width: 100%;
  // padding: 0 15rem;
  overflow: hidden;
  width: 1400px;
  margin: 0 auto;
}
.tvTopInfo{
  overflow: hidden;
}
.tvTopLeft{
  float: left;
  width: 50%;
  overflow: hidden;
}
.tvTopTitleBox{
  height: 4rem;
}
.tvTopTitle{
  float: left;
  font-size: 2.5rem;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: left;
  color: #333333;
  line-height: 3.5rem;
  letter-spacing: 0.04rem;
  cursor: pointer;
}
.columnImg {
  float: left;
  width: 3.5rem;
  height: 3.5rem;
  margin-right: 0.08rem;
  cursor: pointer;
}
.tvIntroduce {
  height: 1.5rem;
  font-size: 1.2rem;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: left;
  color: #8d8d8d;
  line-height: 1.38rem;
  margin-top: 0.5rem;
  cursor: pointer;
}
.tvIntroduce label {
  margin-right: 1rem;
  cursor: pointer;
}
.tvTopRight{
  float: right;
  width: 50%;
  overflow: hidden;
  cursor: pointer;
}
.tvTopText{
  width: 42.5rem;
  font-size: 1rem;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: justify;
  text-indent: 2em;
  color: #333333;
  line-height: 2rem;
  padding: 3.8rem 0 0 2.5rem;
}
.moreBox{
  height: 1.5rem;
  padding-right: 3rem;
  cursor: pointer;
  margin-top: 2rem;
}
.moreImg{
  float: right;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
}
.moreBut{
  float: right;
  height: 1.5rem;
  line-height: 1.5rem;
  font-size: 1rem;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: left;
  color: #333333;
  margin-right: 0.94rem;
}
.columnDataBox{
  width: 100% !important;
  height: 22.44rem;
  background: #ffffff;
  margin-top: 5.69rem;
}
.columnDataLi{
  height: 100%;
  background: #f08307;
  color: #ffffff;
  padding: 4.19rem 0 0 2.5rem;
}
.tvProgramaImg{
  width: 4rem;
  height: 4rem;
  cursor: pointer;
}
.tvProgramaNum{
  height: 4.13rem;
  font-size: 3rem;
  font-family: DIN, DIN-Bold;
  font-weight: 700;
  text-align: left;
  line-height: 4.13rem;
  margin-top: 1.75rem;
  cursor: pointer;
}
.font_3{
  font-size: 1.13rem;
  font-weight: 500;
  margin-left: -12px;
}
.tvProgramaText{
  height: 1.5rem;
  font-size: 1.5rem;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: left;
  line-height: 1.5rem;
  margin-top: 2rem;
  letter-spacing: 2px;
  cursor: pointer;
}
.columnDataLi2{
  height: 100%;
  color: #333333;
  padding: 4.19rem 0 0 2.5rem;
}
.borRig{
  border-right: 1px solid #F0F3F8;
}
.borBom1{
  border-bottom: 0.5rem solid #FFCE7A;
}
.borBom2{
  border-bottom: 0.5rem solid #FFA844;
}
.xinkeBox{
  // width: 100%;
  // padding: 0 15rem;
  width: 1400px;
  margin: 0 auto;
  overflow: hidden;
}
.xinkeFundTitle{
  font-size: 2.5rem;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: left;
  color: #333333;
  line-height: 2.5rem;
  letter-spacing: 0.04rem;
}
.xinkeFundIntroduce {
  height: 1.5rem;
  font-size: 1.2rem;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: left;
  color: #8d8d8d;
  line-height: 1.38rem;
  margin-top: 1.5rem;
  cursor: pointer;
}
.xinkeFundIntroduce label {
  margin-right: 1rem;
  cursor: pointer;
}
.xinkeFundContainer{
  height: 36.06rem;
  background: #ffffff;
  box-shadow: 0px 6px 30px 0px rgba(0,0,0,0.16);
  margin-top: 4.13rem;
  padding: 3.06rem 6rem;
  background-image: url("http://file.thinkervc.cn/8qkk1qlf34isk3rynxun6ich0a2ghfnzber5aov9pkp6y1y14zpdffsu1cc4ytgtsmuksbopfpxzpane.png");
  background-size: 100% 100%;
}
.enterpriseBox{
  float: left;
  width: 20%;
  height: 6.38rem;
  // background: #ffffff;
  border: 0.06rem solid #E3E3E3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.enterpriseBox:hover .enterpriseImgBox{
  width: calc(70% + 2rem);
}
.enterpriseImgBox{
  width: 70%;
}
.enterpriseImg{
  width: 100%;
}
.xzActive{
  border: 0.06rem solid rgba(240,155,7,0.34);
  box-shadow: 0.06rem 0.5rem 0.5rem 0rem rgba(174,110,0,0.14);
}
.xzActive .enterpriseImgBox{
  width: calc(70% + 2rem) !important;
}
.enterpriseName{
  height: 1.75rem;
  font-size: 1.75rem;
  font-family: Source Han Sans CN, Source Han Sans CN-Medium;
  font-weight: 500;
  text-align: left;
  color: #666666;
  margin-top: 2.5rem;
}
.enterpriseText{
  height: 9.5rem;
  font-size: 1.13rem;
  font-family: Source Han Sans CN, Source Han Sans CN-Normal;
  font-weight: normal;
  text-align: justify;
  text-indent: 2em;
  color: #999999;
  line-height: 2.5rem;
  margin-top: 1.9rem;
}
.trainingCampBox{
  width: 100%;
  height: 20rem;
  padding: 0 3.5rem;
  margin-top: 2rem;
}
.trainingCampLeft{
  width: 48%;
  height: 100%;
  background-color: #F08307;
  padding: 1.5rem 2.66rem 0 1.35rem;
}
.trainingCampRight{
  width: 48%;
  height: 100%;
}
.trainingCampImg{
  width: 1.1rem;
  height: 2.5rem;
}
.trainingCampText{
  font-size: 1rem;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: justify;
  text-indent: 2em;
  color: #ffffff;
  line-height: 2rem;
}
.exhibitionImg{
  width: 100%;
  height: 10rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
}
.exhibitionImg:hover{
  width: calc(100% + 1rem);
  height: 11rem;
}
.exhibitionBox{
  height: 9.5rem;
  cursor: pointer;
}
.exhibitionImg2{
  width: calc(100% - 13.75rem);
  height: 9.5rem;
  cursor: pointer;
}
.exhibitionImg2:hover{
  width: calc(100% - 12.75rem);
  height: 10.5rem;
}
.readMoreBox{
  width: 12.25rem;
  height: 9.5rem;
  background: #ffffff;
  cursor: pointer;
}
.readMoreImg{
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
}
.readMoreBut{
  height: 1.5rem;
  font-size: 1rem;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  color: #F08307;
  line-height: 1.5rem;
  margin-right: 0.94rem;
}
.mentorBox{
  width: 100%;
  height: 22rem;
  margin-top: 2rem;
}
.mentorLi{
  width: 100%;
  height: 100%;
  display: flex;
  padding-bottom: 20px;
  margin-top: 1.2rem;
}
.mentorInfo{
  width: 20%;
  min-width: 16.88rem;
  height: 100%;
  padding: 0.75rem 0.81rem;
}
.mentorInfo:hover{
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px #efeae4;
  margin-top: -1.2rem;
  position: relative;
  z-index: 999999;
}
.mentorImg{
  width: 100%;
  height: 15.25rem;
}
.mentorIntroduce{
  height: 1.25rem;
  margin-top: 0.5rem;
}
.mentorName{
  float: left;
  font-size: 1.25rem;
  font-family: Source Han Sans CN, Source Han Sans CN-Bold;
  font-weight: 700;
  color: #333333;
  line-height: 1.25rem;
}
.mentorPosition{
  float: right;
  font-size: 1rem;
  font-family: Source Han Sans CN, Source Han Sans CN-Medium;
  font-weight: 500;
  color: #333333;
  line-height: 1.25rem;
}
.mentorIntroduce2{
  height: 1rem;
  margin-top: 1rem;
}
.companyName{
  float: left;
  font-size: 1rem;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  color: #929292;
  line-height: 1rem;
}
.spot1{
  float: right;
  width: 0.5rem;
  height: 0.5rem;
  background: #3e81f6;
  margin: 0.25rem 0.19rem 0 0;
}
.spot2{
  float: right;
  width: 0.5rem;
  height: 0.5rem;
  background: #F08307;
  margin: 0.25rem 0.19rem 0 0;
}
.theMeetBox{
  height: 17.31rem;
  margin-bottom: 0.88rem;
}
.theMeetImg{
  width: 40%;
  height: 100%;
  cursor: pointer;
}
.theMeetImg2{
  width: calc(30% - 1rem);
  height: 100%;
  cursor: pointer;
}
.theMeetImg:hover{
  width: calc(40% + 1rem);
  height: calc(100% + 1rem);
  margin-top: -1rem;
}
.theMeetImg2:hover{
  width: calc(29% + 1rem);
  height: calc(100% + 1rem);
  margin-top: -1rem;
}

/* 新闻资讯 */
.newsInfoBox{
  width: 1400px;
  margin: 0 auto;
  overflow: hidden;
  padding: 50px 0;
}
.newsInfoLeft{
  float: left;
  width: 600px;
  margin-right: 40px;
}
.newsInfoImg{
  width: 100%;
  height: 350px;
}
.newsInfoTitle{
  height: 50px;
  font-size: 22px;
  font-family: Source Han Sans CN, Source Han Sans CN-Bold;
  font-weight: 700;
  text-align: left;
  color: #000000;
  line-height: 50px;
  cursor: pointer;
}
.newsInfoText{
  font-size: 16px;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: justify;
  color: #7b7b7b;
  line-height: 1.5;
  max-height: 390px
}
.newsInfoRight{
  float: left;
  width: calc(100% - 640px);
}
.newsInfoTopic{
  height: 42px;
  border-bottom: 4px solid #F08307;
}
.topicName{
  font-size: 22px;
  font-family: Source Han Sans CN, Source Han Sans CN-Bold;
  font-weight: 700;
  text-align: left;
  color: #000000;
}
.examineAllBut{
  position: relative;
  height: 18px;
  line-height: 18px;
  font-size: 16px;
  color: #999999;
  cursor: pointer;
}
.newsLi {
    width: 100%;
    height: 45px;
    cursor: pointer;
    border-bottom: 1px solid #E6E6E6;
}
.newsTag {
    float: left;
    width: 6px;
    height: 6px;
    background: #555555;
    border-radius: 50%;
    margin-top: 19px;
}

.newsTitle {
    float: left;
    width: 75%;
    height: 45px;
    font-size: 14px;
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    font-weight: 400;
    color: #333333;
    line-height: 45px;
    margin-left: 4px;
}

.newsTime {
    float: right;
    height: 45px;
    font-size: 12px;
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    font-weight: 400;
    color: #999999;
    line-height: 45px;
}

.newsLi:hover .newsTag {
    background: #F08307;
}
.newsLi:hover .newsTitle {
    color: #F08307;
}
.newsLi:hover .newsTime {
    color: #F08307;
}
.ruleBox{
  width: 100%;
  background-color: #FCFAF8;
  height: calc(100vh - 60px);
  margin-top: 60px;
}
.rule1200{
  width: 1400px;
  margin: 0 auto;
  overflow: hidden;
  padding-top: 50px;
}
.ruleTopBox{
  width: 100%;
  overflow: hidden;
  margin-bottom: 50px;
}
.ruleTopLeft{
  float: left;
  width: calc(100% - 620px);
}
.ruleTitle{
  font-size: 48px;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: left;
  color: #333333;
  letter-spacing: 0.56px;
}
.subheadSubhead{
  font-size: 18px;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: left;
  color: #8d8d8d;
  margin-top: 10px;
  letter-spacing: 0.24px;
}
.ruleTopRight{
  float: right;
  width: 600px;
  font-size: 16px;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: justify;
  color: #333333;
  line-height: 1.5;
  padding-top: 10px;
}
.ruleLi{
  width: 100%;
  height: 270px;
  margin-bottom: 20px;
}
.ruleName{
  font-size: 26px;
  font-family: Source Han Sans CN, Source Han Sans CN-Bold;
  font-weight: 700;
  text-align: left;
  color: #333333;
  margin-bottom: 25px;
}
.ruleBz{
  position: absolute;
  width: 6px;
  height: 6px;
  background: #f08307;
  border-radius: 50%;
  left: 0;
  top: 10px;
}
.ruleText{
  position: relative;
  width: 100%;
  padding-left: 13px;
  font-size: 1rem;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: justify;
  color: #333333;
  margin-bottom: 15px;
  line-height: 1.5;
}
.teamworkLi{
  width: 100%;
  height: 600px;
  background-color: #ffffff;
}
.teamworkImg{
  width: 100%;
  height: 330px;
}
.teamworkInfo{
  width: 100%;
  padding: 40px 24px 0 24px;
}
.teamworkName{
  font-size: 22px;
  font-family: Source Han Sans CN, Source Han Sans CN-Bold;
  font-weight: 700;
  text-align: left;
  color: #333333;
  letter-spacing: 0.24px;
  margin-bottom: 25px;
}
.teamworkVal{
  margin-bottom: 20px;
}
.teamworkBut{
  width: 100%;
  height: 36px;
  background: #f08307;
  border-radius: 4px;
  line-height: 36px;
  text-align: center;
  font-size: 16px;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
}
.dialogTitle{
  font-size: 18px;
  line-height: 60px;
  color: #303133;
  text-align: center;
  margin-bottom: 40px;
}

@media screen and (max-width: 1200px) {
  body {
    overflow: scroll !important;
  }
  .carouselContainers{
    display: none;
  }
  .mobileH5Box{
    width: 100%;
    // min-height: 80vh;
    display: block;
  }
  .mobileCarouselBox{
    position: relative;
    background-color: #efefef;
    height: 229px;
    overflow: hidden;
    margin-top: -2px;
  }
  .mobileH5Content{
    width: 96%;
    margin: 0 auto;
    padding-top: 1rem;
  }
  .programaContent{
    padding: 15px 15px 15px 24px;
    height: 88px;
    background: #fffbf6;
    border: 2px solid #fff2e5;
    border-radius: 12px;
    box-shadow: 0 3px 6px 0 #fff6ee;
  }
  .fontBold{
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0px;
  }
  .text13{
    font-size: 14px;
    letter-spacing: -1px;
  }
  .text12{
    font-size: 12px;
  }
  .text16{
    font-size: 16px;
  }
  .investBox{
    height: 80px;
    border-radius: 12px;
    box-shadow: 0 3px 6px 0 #e9e9e9;
    padding: 15px;
    margin-top: 10px;
  }
  .marginLr{
    margin-left: 25px;
    margin-right: 15px;
    width: 1px;
    height: 21px;
    border: 1px solid #eee;
  }
  .text10{
    font-size: 10px;
  }
  .text14{
    font-size: 14px;
  }
  .gridH5Box{
    margin-top: 12px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 8px;
  }
  .gridH5Li{
    height: 70px;
    background: #f9f9f9;
    border-radius: 12px;
    align-items: center;
    padding: 15px;
  }
}


@media screen and (max-width: 1500px) {
  .tvBox{
    width: 1350px;
  }
  .introduceText{
    width: 30rem;
    margin-top: 2.13rem;
  }
  .videoAvatar{
    width: 37rem;
  }
  .tvTopText{
    padding-left: 0 !important;
    width: auto;
    padding-right: 10px;
  }
  .xinkeBox{
    width: 1350px;
  }
  .trainingCampBox{
    padding: 0 1.5rem;
  }
  .mentorInfo{
    min-width: auto;
  }
}
</style>
