<template>
  <div class="handpickBox">
    <el-row style="width: 100%">
      <el-col :span="24" class="handpickBackImg">
        <el-image :src="programBackImg" fit="cover" style="width: 100%; height: 100%;cursor: pointer;"></el-image>
      </el-col>
    </el-row>

    <!-- 精选内容  -->
    <div class="handpick-1200">
      <div class="titleBox">
        <el-divider>精选内容</el-divider>
      </div>

      <el-menu text-color="#666679" active-text-color="#0079fe" :default-active="queryParams.type" class="el-menu-demo" mode="horizontal" @select="typeSelect">
          <el-menu-item index="电视节目">电视节目</el-menu-item>
          <el-menu-item index="峰会论坛">峰会论坛</el-menu-item>
          <el-menu-item index="课程">课程</el-menu-item>
      </el-menu>

      <el-row :gutter="18" style="width: 100%;">
        <el-col :span="6" v-for="(res) in handpickList">
          <div class="programLi" @click="HandpickDetails(res.id)">
            <div class="programImg"><el-image :src="res.cover" fit="fill" style="width: 100%;height: 100%;cursor: pointer;"></el-image></div>
            <div class="programInfo">
              <div class="programName line1">{{res.title}}</div>
              <div class="programName line1">发布时间：{{formatDate(res.publishTime)}}</div>
            </div>
          </div>
        </el-col>
      </el-row>

      <!-- 分页 -->
      <div class="paginationBox verticals">
      	<el-pagination background :page-size="queryParams.pageSize" layout="prev, pager, next" @size-change="changeSize" @current-change="changeCurrent" :total="total"></el-pagination>
      </div>




    </div>
  </div>
  </div>
</template>

<script>
  import { getVideoList } from '@/api/handpick/handpick'
  import { getToken,getVipStatus } from '@/utils/auth'
  export default {
    components: {},
    data() {
      return {
        programBackImg:"http://djs.thinkerwork.com/20230824144117412_未标题-1@2x.png",
        coverImg:"https://file.thinkervc.cn/b1b341f696ab49088ad008e04382d26c.png",
        //精选内容列表
        handpickList:[],
        //总条数
        total:0,
        // 查询参数
        queryParams: {
          type: '电视节目',
          pageNum: 1,
          pageSize: 12
        },
        vipStatus:getVipStatus(),
        token:getToken(),
      };
    },
    created() {
      this.getList();
    },
    methods: {
      /* 精选内容列表 */
      getList(){
        if(!this.token){
          this.$confirm('您还未登录，请登录后查看', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
              this.$router.push({ name: 'Login', query: {} });
          }).catch(() => {
              this.$router.push({ path: "/" });
              sessionStorage.setItem('activeIndex', "1")
          });
          return
        }

        if(this.vipStatus == 1){
          getVideoList(this.queryParams).then(res => {
          	if (res.code === 200) {
          	 this.handpickList = res.data.records;
          	 this.total = res.data.total;
          	}
          });
        }else{
          this.$confirm('您还不是会员，成为会员可查看', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
              this.$router.push({ path: "/" });
              sessionStorage.setItem('activeIndex', "1")
          }).catch(() => {
              this.$router.push({ path: "/" });
              sessionStorage.setItem('activeIndex', "1")
          });
        }
      },
      /* 类型切换 */
      typeSelect(e) {
          this.queryParams.type = e
          this.queryParams.pageNum = 1
          this.handpickList = []
          this.getList();
      },
      /* 时间转成年月日 */
      formatDate(dateStr) {
        const date = new Date(dateStr);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      },
      //分页监听
      changeSize(val){
      	this.queryParams.pageNum = val;
      	this.getList();
      },
      //分页监听
      changeCurrent(val){
      	this.queryParams.pageNum = val;
      	this.getList();
      },
      /* 跳转详情 */
      HandpickDetails(id){
      	this.$router.push({ name: 'HandpickDetails', query: {id:id} });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .handpickBox{
    width: 100%;
    min-height: 100vh;
  }
  .handpickBackImg{
    width: 100%;
    height: 360px;
    margin-bottom: 80px;
  }
  .handpick-1200{
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
  }
  .titleBox{
    padding: 0 350px;
    margin-bottom: 40px;
    overflow: hidden;
  }
  ::v-deep .el-divider__text{
    font-size: 30px;
    font-family: Source Han Sans CN, Source Han Sans CN-Bold;
    font-weight: 700;
    color: #000000;
    letter-spacing: 1.6px;
  }
  ::v-deep .el-divider {
      background-color: #F08307;
  }
  .programLi{
    width: 100%;
    height: 330px;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 0px 10px 0px #dbdbdb;
    cursor: pointer;
    margin-top: 20px;
  }
  .programImg{
    width: 100%;
    height: 220px;
  }
  .programInfo{
    width: 100%;
    padding: 20px 14px;
  }
  .programName{
    font-size: 16px;
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    font-weight: 400;
    text-align: left;
    color: #333333;
    letter-spacing: 0.82px;
    margin-bottom: 15px;
  }
  .line1 {
  	overflow: hidden;
  	text-overflow: ellipsis;
  	white-space: nowrap;
  }
  .paginationBox{
  	width: 100%;
  	height: 80px;
    margin-top: 60px;
  }
  .verticals{
  	display: flex;
  	flex-direction: column;
  	align-items: center;
  }

  @media screen and (max-width: 1200px) {}
</style>
